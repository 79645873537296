import React from 'react'

export default class CRUDIcons {
    static create(onClick, className){
        return (
            <svg
                height="16"
                viewBox="0 0 16 16"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                onClick={onClick}
            >
                <path
                    d="M16 7.5V8.5H8.5V16H7.5V8.5H0V7.5H7.5V0H8.5V7.5H16Z"
                    fill="white"
                />
            </svg>
        )
    }  
    static restore(onClick, className){
        return (
            <svg
                height="14"
                viewBox="0 0 15 14"
                width="15"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                onClick={onClick}
	        >
                <path
                    clip-rule="evenodd"
                    d="M10.8734 12.8332H14.0016V14H9.91779L9.33439 13.4166V9.33274H10.5012V11.6664C11.547 10.8817 12.2989 9.7685 12.6363 8.50535C12.9737 7.2422 12.877 5.90233 12.3618 4.7007C11.8466 3.49907 10.9426 2.50533 9.79503 1.87892C8.64743 1.25251 7.32267 1.02973 6.03331 1.24633C4.74394 1.46294 3.5647 2.10636 2.68478 3.07338C1.80486 4.04039 1.27526 5.27495 1.18096 6.57898C1.08665 7.88301 1.4331 9.18092 2.16473 10.2645C2.89635 11.348 3.97074 12.1544 5.21553 12.5543L4.86315 13.6675C3.35256 13.1831 2.05126 12.2 1.17244 10.8794C0.293606 9.55871 -0.110646 7.97874 0.025904 6.39828C0.162454 4.81782 0.831711 3.33059 1.92403 2.18024C3.01636 1.02988 4.46698 0.284604 6.03826 0.0664978C7.60954 -0.151608 9.20831 0.170389 10.5727 0.97974C11.937 1.78909 12.986 3.03781 13.5479 4.52132C14.1097 6.00483 14.1511 7.63518 13.6652 9.14528C13.1793 10.6554 12.195 11.9557 10.8734 12.8332V12.8332Z"
                    fill-rule="evenodd"
                />
            </svg>
        )
    } 
    static update(onClick, className) {
		return (
			<svg
                height="16"
                viewBox="0 0 16 16"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
				onClick={onClick}
			>
				<path
					d="M15.3334 3.0651C15.3334 3.38021 15.2737 3.68576 15.1544 3.98177C15.035 4.27778 14.8608 4.54036 14.6316 4.76953L5.19995 14.2012L0.666748 15.3327L1.79826 10.7995L11.2299 1.36784C11.4591 1.13867 11.7217 0.96441 12.0177 0.845052C12.3137 0.725694 12.6192 0.666016 12.9343 0.666016C13.2638 0.666016 13.5741 0.728082 13.8653 0.852214C14.1565 0.976345 14.4096 1.14822 14.6244 1.36784C14.8393 1.58746 15.0111 1.84288 15.1401 2.13411C15.269 2.42535 15.3334 2.73568 15.3334 3.0651ZM3.00138 10.8926C3.50746 11.0597 3.94192 11.3247 4.30477 11.6875C4.66762 12.0503 4.93498 12.4872 5.10685 12.998L12.8484 5.24935L10.7501 3.15104L3.00138 10.8926ZM1.92716 14.0723L4.28328 13.485C4.23554 13.2654 4.15915 13.0577 4.05412 12.862C3.94908 12.6662 3.81779 12.492 3.66024 12.3392C3.50269 12.1864 3.32842 12.0575 3.13745 11.9525C2.94648 11.8474 2.7388 11.7687 2.5144 11.7161L1.92716 14.0723ZM13.5001 4.59766C13.6194 4.4783 13.734 4.3661 13.8438 4.26107C13.9536 4.15603 14.0515 4.04622 14.1375 3.93164C14.2234 3.81706 14.2902 3.69054 14.338 3.55208C14.3857 3.41363 14.412 3.25369 14.4167 3.07227C14.4167 2.86697 14.3786 2.676 14.3022 2.49935C14.2258 2.3227 14.1184 2.16515 13.9799 2.02669C13.8414 1.88824 13.6839 1.78082 13.5072 1.70443C13.3306 1.62804 13.1372 1.58746 12.9272 1.58268C12.7457 1.58268 12.5882 1.60655 12.4545 1.6543C12.3208 1.70204 12.1943 1.77127 12.075 1.86198C11.9556 1.95269 11.8434 2.05056 11.7384 2.1556C11.6333 2.26063 11.5211 2.37522 11.4018 2.49935L13.5001 4.59766Z"
				/>
			</svg>
		)
	}
    static delete(onClick, className){
        return  (
            <svg
                height="12"
                viewBox="0 0 12 12"
                width="12"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                onClick={onClick}
            >
                <path d="M0.088588 0.215749L0.146448 0.146509C0.320008 -0.0270611 0.589438 -0.0463409 0.784308 0.088649L0.853548 0.146509L6 5.29306L11.1464 0.146509C11.32 -0.0270611 11.5894 -0.0463409 11.7843 0.088649L11.8536 0.146509C12.0271 0.320069 12.0464 0.589499 11.9114 0.784369L11.8536 0.853609L6.707 6.00006L11.8536 11.1465C12.0271 11.3201 12.0464 11.5895 11.9114 11.7844L11.8536 11.8537C11.68 12.0272 11.4106 12.0465 11.2157 11.9115L11.1464 11.8537L6 6.70706L0.853548 11.8537C0.679988 12.0272 0.410558 12.0465 0.215688 11.9115L0.146448 11.8537C-0.0271221 11.6801 -0.046402 11.4107 0.088588 11.2158L0.146448 11.1465L5.293 6.00006L0.146448 0.853609C-0.0271221 0.680049 -0.046402 0.410619 0.088588 0.215749L0.146448 0.146509L0.088588 0.215749Z" />
            </svg>
        )
    } 
    static download(onClick, className){
        return  (
            <svg 
                width="16" 
                height="16" 
                viewBox="0 0 16 16" 
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                onClick={onClick}
            >
                <path d="M7.56786 10.2658L4.35953 7.05837C4.24606 6.94355 4.18263 6.78849 4.1831 6.62705C4.18357 6.46562 4.2479 6.31093 4.36203 6.19676C4.47617 6.0826 4.63084 6.01823 4.79227 6.01772C4.95371 6.01721 5.10879 6.08059 5.22364 6.19403L7.38881 8.35859L7.38881 1.2781C7.38881 1.11603 7.45319 0.960588 7.5678 0.845982C7.6824 0.731377 7.83784 0.666992 7.99992 0.666992C8.162 0.666992 8.31743 0.731377 8.43204 0.845982C8.54665 0.960588 8.61103 1.11603 8.61103 1.2781V8.35859L10.7762 6.19403C10.8911 6.08059 11.0461 6.01721 11.2076 6.01772C11.369 6.01823 11.5237 6.0826 11.6378 6.19676C11.7519 6.31093 11.8163 6.46562 11.8167 6.62705C11.8172 6.78849 11.7538 6.94355 11.6403 7.05837L8.43197 10.2658C8.31737 10.3804 8.16196 10.4447 7.99992 10.4447C7.83787 10.4447 7.68246 10.3804 7.56786 10.2658ZM15.3333 14.1114V9.83366C15.3333 9.67158 15.2689 9.51614 15.1543 9.40154C15.0397 9.28693 14.8842 9.22255 14.7221 9.22255C14.5601 9.22255 14.4046 9.28693 14.29 9.40154C14.1754 9.51614 14.111 9.67158 14.111 9.83366V14.1114H1.88881V9.83366C1.88881 9.67158 1.82442 9.51614 1.70982 9.40154C1.59521 9.28693 1.43977 9.22255 1.2777 9.22255C1.11562 9.22255 0.960182 9.28693 0.845576 9.40154C0.730971 9.51614 0.666587 9.67158 0.666587 9.83366V14.1114C0.666951 14.4355 0.795837 14.7461 1.02497 14.9753C1.2541 15.2044 1.56477 15.3333 1.88881 15.3337H14.111C14.4351 15.3333 14.7457 15.2044 14.9749 14.9753C15.204 14.7461 15.3329 14.4355 15.3333 14.1114Z"/>
            </svg>
        )
    } 
    static registrate(onClick, className){
        return (
            <svg 
                width="20" 
                height="20" 
                viewBox="0 0 20 20" 
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                onClick={onClick}
            >
                <path d="M4.57146 1.42871C4.11681 1.42871 3.68077 1.60932 3.35928 1.93081C3.03779 2.2523 2.85718 2.68834 2.85718 3.143V16.8573C2.85718 17.3119 3.03779 17.748 3.35928 18.0695C3.68077 18.391 4.11681 18.5716 4.57146 18.5716H15.4286C15.8833 18.5716 16.3193 18.391 16.6408 18.0695C16.9623 17.748 17.1429 17.3119 17.1429 16.8573V8.27014C17.1429 7.74942 16.9357 7.25014 16.5679 6.88157L11.6172 1.93085C11.2957 1.60936 10.8597 1.42873 10.405 1.42871H4.57146ZM3.92861 3.143C3.92861 2.9725 3.99634 2.80899 4.11689 2.68843C4.23745 2.56787 4.40097 2.50014 4.57146 2.50014H10V7.143C10 7.52188 10.1505 7.88524 10.4185 8.15315C10.6864 8.42106 11.0497 8.57157 11.4286 8.57157H16.0715V16.8573C16.0715 17.0278 16.0037 17.1913 15.8832 17.3119C15.7626 17.4324 15.5991 17.5001 15.4286 17.5001H4.57146C4.40097 17.5001 4.23745 17.4324 4.11689 17.3119C3.99634 17.1913 3.92861 17.0278 3.92861 16.8573V3.143ZM15.6715 7.50014H11.4286C11.3339 7.50014 11.243 7.46251 11.1761 7.39553C11.1091 7.32856 11.0715 7.23772 11.0715 7.143V2.90014L15.6715 7.50014Z"/>
            </svg>
        )
    }
    static replace(onClick, className){
        return(
            <svg 
                width="24" 
                height="14" 
                viewBox="0 0 24 14" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
                className={className}
                onClick={onClick}
            >
                <path d="M23.12 4.91014L19.25 1.00014C19.157 0.906416 19.0464 0.832021 18.9246 0.781252C18.8027 0.730484 18.672 0.704346 18.54 0.704346C18.408 0.704346 18.2773 0.730484 18.1554 0.781252C18.0336 0.832021 17.923 0.906416 17.83 1.00014C17.6437 1.18751 17.5392 1.44096 17.5392 1.70514C17.5392 1.96933 17.6437 2.22278 17.83 2.41014L21.39 6.00014H1C0.734784 6.00014 0.48043 6.1055 0.292893 6.29304C0.105357 6.48057 0 6.73493 0 7.00014H0C0 7.26536 0.105357 7.51971 0.292893 7.70725C0.48043 7.89479 0.734784 8.00014 1 8.00014H21.45L17.83 11.6101C17.7363 11.7031 17.6619 11.8137 17.6111 11.9356C17.5603 12.0574 17.5342 12.1881 17.5342 12.3201C17.5342 12.4522 17.5603 12.5829 17.6111 12.7047C17.6619 12.8266 17.7363 12.9372 17.83 13.0301C17.923 13.1239 18.0336 13.1983 18.1554 13.249C18.2773 13.2998 18.408 13.3259 18.54 13.3259C18.672 13.3259 18.8027 13.2998 18.9246 13.249C19.0464 13.1983 19.157 13.1239 19.25 13.0301L23.12 9.15014C23.6818 8.58764 23.9974 7.82515 23.9974 7.03014C23.9974 6.23514 23.6818 5.47265 23.12 4.91014Z"/>
            </svg>
        )
    }
}